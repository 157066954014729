import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

interface TriggerTimes {
  factoryId: number | null;
  plantId: number | null;
  lineId: number | null;
}

@Injectable({
  providedIn: 'root',
})
export class HeaderService {
  lineIdSource = new BehaviorSubject<string | null>(null);
  factoryIdSource = new BehaviorSubject<string | null>(null);
  public autoRefreshSource = new BehaviorSubject<boolean>(false);
  private manualRefreshSource = new Subject<void>();
  private lastApiCallTimeSource = new BehaviorSubject<string | null>(null);
  lineIdsSource = new BehaviorSubject<string[] | null>(null);
  plantIdsSource = new BehaviorSubject<string | null>(null);
  public isOnlyLineChartSource = new BehaviorSubject<boolean>(false);
  public fetchSource = new BehaviorSubject<void>(undefined);

  lineId = this.lineIdSource.asObservable();
  factoryId = this.factoryIdSource.asObservable();
  autoRefresh = this.autoRefreshSource.asObservable();
  manualRefresh = this.manualRefreshSource.asObservable();
  lastApiCallTime = this.lastApiCallTimeSource.asObservable();
  lineIds = this.lineIdsSource.asObservable();
  plantId = this.plantIdsSource.asObservable();
  isOnlyLineChart = this.isOnlyLineChartSource.asObservable();
  private lastTriggerTimes: TriggerTimes = {
    factoryId: null,
    plantId: null,
    lineId: null
  };


  constructor() {}

  setPlantId(plantId: string | null) {
    if (this.isNotImmediateTrigger(plantId, this.plantIdsSource.value, 'plantId')) {
      this.plantIdsSource.next(plantId);
    }
  }

  removePlantId() {
    localStorage.removeItem('plantId');
    this.plantIdsSource.next(null);
  }

  setFactoryId(factoryId: string | null) {
    if (this.isNotImmediateTrigger(factoryId, this.factoryIdSource.value, 'factoryId')) {
      this.factoryIdSource.next(factoryId);
    }
  }
  setFactoryIds(factoryIds: any[] | null) {
    if (factoryIds) {
      let factoryId = '';
      factoryIds.forEach((factory) => {
        factoryId = factoryId + `${factory.id},`;
      });
      this.factoryIdSource.next(factoryId.slice(0, -1));
    }
  }

  removeFactoryId() {
    localStorage.removeItem('factoryId');
    this.factoryIdSource.next(null);
  }

  setLineIds(lineIds: string[] | null) {
    this.lineIdsSource.next(lineIds);
  }

  removeLineIds() {
    localStorage.removeItem('selectedLines');
    this.lineIdsSource.next(null);
  }

  setLineId(lineId: string | null) {
    if (this.isNotImmediateTrigger(lineId, this.lineIdSource.value, 'lineId')) {
      this.lineIdSource.next(lineId);
    }
  }

  removeLineId() {
    localStorage.removeItem('lineId');
    this.lineIdSource.next(null);
  }

  setAutoRefresh(isEnabled: boolean) {
    this.autoRefreshSource.next(isEnabled);
    localStorage.setItem('isAutoRefreshEnabled', JSON.stringify(isEnabled));
  }

  getInitialAutoRefreshState(): boolean {
    const storedValue = localStorage.getItem('isAutoRefreshEnabled');
    return storedValue ? JSON.parse(storedValue) : false;
  }

  setManualRefresh() {
    this.manualRefreshSource.next();
  }

  setLastChartLoadedTime(time: string) {
    this.lastApiCallTimeSource.next(time);
  }

  setIsOnlyLineChart(isEnabled: boolean) {
    this.isOnlyLineChartSource.next(isEnabled);
    localStorage.setItem('isOnlyLineChart', JSON.stringify(isEnabled));
  }

  getInitialIsOnlyLineChart(): boolean {
    const storedValue = localStorage.getItem('isOnlyLineChart');
    return storedValue ? JSON.parse(storedValue) : false;
  }

  private isNotImmediateTrigger(newValue: string | null, existingValue: string | null, triggerKey: keyof TriggerTimes): boolean {
    const currentTime = Date.now();
    const lastTriggerTime = this.lastTriggerTimes[triggerKey];
    const isNotImmediate = (newValue !== existingValue && (!lastTriggerTime || (currentTime - lastTriggerTime > 3000)));
    
    if (isNotImmediate) {
      this.lastTriggerTimes[triggerKey] = currentTime;
    }
    return isNotImmediate;
  }
}
